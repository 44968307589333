import React, { useState, useEffect } from "react";
import { Navbar, Footer } from "../../../components";
import Locationscroll from "../../../components/Locationscroll/Locationscroll";
import { useAuth } from "../../../AuthProvider";
import Reports from "./Reports";
import StudentGrades from "./StudentGrades";
import AdminSettings from "./AdminSettings";
import StudentAttendance from "./StudentAttendance";
import Overview from "./Overview";
import Students from "./Students"; // Future import for Students component
import Economics from "./Economics"; // Import Economics component
import "./Intranet.css";

const Intranet = () => {
  const { isAdmin } = useAuth();
  const [activePage, setActivePage] = useState("overview"); // Default to overview

  // Function to update both state, URL hash, and localStorage
  const updateActivePage = (page) => {
    setActivePage(page);
    window.location.hash = page; // Update the URL hash
    localStorage.setItem("lastPage", page); // Save to localStorage
  };

  useEffect(() => {
    const handleHashChange = () => {
      const hash = window.location.hash.substring(1); // Get hash without '#'
      setActivePage(hash || "overview"); // Default to overview if no hash
    };

    // On component mount, check localStorage for the last visited page
    const lastPage = localStorage.getItem("lastPage");
    if (lastPage) {
      setActivePage(lastPage); // Set to the last page if found
      window.location.hash = lastPage; // Update the URL hash
    } else {
      handleHashChange(); // Handle the hash if no localStorage
    }

    // Listen for hash changes
    window.addEventListener("hashchange", handleHashChange);

    // Cleanup listener on component unmount
    return () => {
      window.removeEventListener("hashchange", handleHashChange);
    };
  }, []);

  const renderPage = () => {
    switch (activePage) {
      case "overview":
        return <Overview />;
      case "reports":
        return <Reports />;
      case "studentattendance":
        return <StudentAttendance />;
      case "studentgrades":
        return <StudentGrades />;
      case "students": // Add case for Students
        return <Students />;
      case "economics": // New case for Economics
        return <Economics />;
      case "adminsettings":
        return isAdmin ? <AdminSettings /> : <Overview />; // Only render if admin
      default:
        return <Overview />;
    }
  };

  return (
    <>
      <div>
        <Locationscroll />
        <Navbar isAdmin={isAdmin} />
        <div className="row2" id="flex">
          <div className="main">
            {/* Navigation section */}
            <div className="intranet-navigation">
              <button
                className={`nav-button ${
                  activePage === "overview" ? "active" : ""
                }`}
                onClick={() => updateActivePage("overview")}
              >
                Overview
              </button>
              <button
                className={`nav-button ${
                  activePage === "reports" ? "active" : ""
                }`}
                onClick={() => updateActivePage("reports")}
              >
                Reports
              </button>
              <button
                className={`nav-button ${
                  activePage === "economics" ? "active" : ""
                }`}
                onClick={() => updateActivePage("economics")}
              >
                Economics
              </button>
              <button
                className={`nav-button ${
                  activePage === "students" ? "active" : ""
                }`}
                onClick={() => updateActivePage("students")}
              >
                Students
              </button>
              <button
                className={`nav-button ${
                  activePage === "studentattendance" ? "active" : ""
                }`}
                onClick={() => updateActivePage("studentattendance")}
              >
                Attendance
              </button>
              <button
                className={`nav-button ${
                  activePage === "studentgrades" ? "active" : ""
                }`}
                onClick={() => updateActivePage("studentgrades")}
              >
                Grades
              </button>

              {isAdmin && (
                <button
                  className={`nav-button ${
                    activePage === "adminsettings" ? "active" : ""
                  }`}
                  onClick={() => updateActivePage("adminsettings")}
                >
                  Admin
                </button>
              )}
            </div>

            <div className="intranet-content">{renderPage()}</div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default Intranet;
