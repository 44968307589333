import React from "react";
import "./Innsamling.css";
import JobPostForm from "./JobPostForm";

const Utlysning = () => {
  return (
    <div>
      <div className="content-wrapper">
        <p className="event-description">
          Kan du ansette en eller flere elever? Fyll ut skjemaet nedenfor eller{" "}
          <u>
            <a href="mailto:magnus.heide@osloskolen.no">send en mail</a>
          </u>
          . Minsteprisen for en ungdomsskoleelev er 300 kr.
        </p>
      </div>
      <h2>Opprett utlysning</h2>
      <div className="tab-content">
        <JobPostForm />
      </div>
    </div>
  );
};

export default Utlysning;
