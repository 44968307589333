import React, { useState, useEffect } from "react";
import {
  getFirestore,
  collection,
  addDoc,
  getDocs,
  updateDoc,
  doc,
} from "firebase/firestore";
import Locationscroll from "../../../components/Locationscroll/Locationscroll";
import { useAuth } from "../../../AuthProvider";

const AdminSettings = () => {
  const { isAdmin } = useAuth();
  const [newSubject, setNewSubject] = useState("");
  const [newAbbreviation, setNewAbbreviation] = useState(""); // New state for abbreviation
  const [newTeacher, setNewTeacher] = useState(""); // New state for teacher when adding subject
  const [newExam, setNewExam] = useState({ name: "", classId: "" }); // State for new exam
  const [subjects, setSubjects] = useState([]);
  const [classes, setClasses] = useState([]);
  const [staff, setStaff] = useState([]);
  const [exams, setExams] = useState([]);
  const [editableSubject, setEditableSubject] = useState({}); // Track which subject is being edited
  const [editableClass, setEditableClass] = useState({});
  const [editableExam, setEditableExam] = useState({});

  const fetchData = async () => {
    const db = getFirestore();
    const subjectsCollection = collection(db, "subjects");
    const classesCollection = collection(db, "students");
    const staffCollection = collection(db, "staff");
    const examsCollection = collection(db, "exams");

    const subjectsSnapshot = await getDocs(subjectsCollection);
    const classesSnapshot = await getDocs(classesCollection);
    const staffSnapshot = await getDocs(staffCollection);
    const examsSnapshot = await getDocs(examsCollection);

    const subjectsData = subjectsSnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    const classesData = classesSnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
      class_teacher: doc.data().class_teacher || "", // default to empty string if no value
      principal: doc.data().principal || "", // default to empty string if no value
    }));
    const staffData = staffSnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    const examsData = examsSnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    setSubjects(subjectsData);
    setClasses(classesData);
    setEditableClass(
      classesData.reduce((acc, classData) => {
        acc[classData.id] = {
          class_teacher: classData.class_teacher,
          principal: classData.principal,
        };
        return acc;
      }, {})
    );
    setStaff(staffData);
    setExams(examsData);
    setEditableExam(
      examsData.reduce((acc, exam) => {
        acc[exam.id] = { name: exam.name, classId: exam.classId };
        return acc;
      }, {})
    );

    setEditableClass(
      classesData.reduce((acc, classData) => {
        acc[classData.id] = {
          class_teacher: classData.class_teacher,
          principal: classData.principal,
        };
        return acc;
      }, {})
    );
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleAddSubject = async () => {
    if (newSubject.trim() && newAbbreviation.trim() && newTeacher.trim()) {
      const db = getFirestore();
      await addDoc(collection(db, "subjects"), {
        name: newSubject,
        abbreviation: newAbbreviation, // Save abbreviation
        teacherId: newTeacher, // Save selected teacher
      });
      setNewSubject(""); // Clear the input
      setNewAbbreviation(""); // Clear abbreviation input
      setNewTeacher(""); // Clear teacher selection
      alert("Subject added successfully!");
      await fetchData(); // Refetch data after adding a new subject
    }
  };

  const handleAddExam = async () => {
    if (newExam.name.trim() && newExam.classId) {
      const db = getFirestore();
      await addDoc(collection(db, "exams"), {
        name: newExam.name,
        classId: newExam.classId,
      });
      setNewExam({ name: "", classId: "" }); // Clear the input
      alert("Exam added successfully!");
    }
  };

  const handleUpdateExam = async (examId) => {
    const db = getFirestore();
    const examRef = doc(db, "exams", examId);

    const updatedExam = editableExam[examId];

    await updateDoc(examRef, {
      name: updatedExam.name,
    });

    alert("Exam updated successfully!");
    await fetchData(); // Optionally, refetch data after updating if needed
  };

  const handleEditExam = (examId, field, value) => {
    setEditableExam((prev) => ({
      ...prev,
      [examId]: {
        ...prev[examId],
        [field]: value,
      },
    }));
  };

  const handleUpdateSubject = async (subjectId) => {
    const db = getFirestore();
    const subjectRef = doc(db, "subjects", subjectId); // Correct variable name

    // Find the original subject from the list
    const originalSubject = subjects.find(
      (subject) => subject.id === subjectId
    );

    await updateDoc(subjectRef, {
      // Correct document reference
      name: editableSubject[subjectId]?.name || originalSubject.name,
      abbreviation:
        editableSubject[subjectId]?.abbreviation ||
        originalSubject.abbreviation,
      teacherId:
        editableSubject[subjectId]?.teacherId || originalSubject.teacherId,
    });

    alert("Subject updated successfully!");
    await fetchData();
  };

  const handleEditSubject = (subjectId, field, value) => {
    setEditableSubject((prev) => ({
      ...prev,
      [subjectId]: {
        ...prev[subjectId],
        [field]: value,
      },
    }));
  };

  const handleUpdateClass = async (classId) => {
    const db = getFirestore();
    const classRef = doc(db, "students", classId);

    const originalClass = classes.find((classData) => classData.id === classId);

    await updateDoc(classRef, {
      name: editableClass[classId]?.name || originalClass.name,

      class_teacher:
        editableClass[classId]?.class_teacher || originalClass.class_teacher,
      principal: editableClass[classId]?.principal || originalClass.principal,
    });

    alert("Class updated successfully!");
    await fetchData();
  };

  const handleEditClass = (classId, field, value) => {
    setEditableClass((prev) => ({
      ...prev,
      [classId]: {
        ...prev[classId],
        [field]: value,
      },
    }));
  };

  return (
    <div>
      <Locationscroll />
      <div className="row2" id="flex">
        <div className="main">
          <div>
            <h2>Admin Settings</h2>
          </div>
          <br />

          <div>
            <h3>Create New Exam for a Class</h3>
            <input
              type="text"
              placeholder="Enter exam name"
              value={newExam.name}
              onChange={(e) => setNewExam({ ...newExam, name: e.target.value })}
            />
            <select
              value={newExam.classId}
              onChange={(e) =>
                setNewExam({ ...newExam, classId: e.target.value })
              }
            >
              <option value="">Select Class</option>
              {classes.map((classData) => (
                <option key={classData.id} value={classData.id}>
                  {classData.name}
                </option>
              ))}
            </select>
            <button onClick={handleAddExam}>Create Exam</button>
          </div>
          <br />
          <div>
            <h3>Manage Exams</h3>
            <table>
              <thead>
                <tr>
                  <th>Exam Name</th>
                  <th>Class</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {exams.map((exam) => (
                  <tr key={exam.id}>
                    <td>
                      <input
                        type="text"
                        value={editableExam[exam.id]?.name || ""}
                        onChange={(e) =>
                          handleEditExam(exam.id, "name", e.target.value)
                        }
                      />
                    </td>
                    <td>
                      {classes.find(
                        (classData) => classData.id === exam.classId
                      )?.name || "Unknown Class"}
                    </td>
                    <td>
                      <button onClick={() => handleUpdateExam(exam.id)}>
                        Update
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <br />
          <div>
            <h3>Add New Subject</h3>
            <input
              type="text"
              placeholder="Enter subject name"
              value={newSubject}
              onChange={(e) => setNewSubject(e.target.value)}
            />
            <input
              type="text"
              placeholder="Enter abbreviation"
              value={newAbbreviation}
              onChange={(e) => setNewAbbreviation(e.target.value)}
            />
            <select
              value={newTeacher}
              onChange={(e) => setNewTeacher(e.target.value)}
            >
              <option value="">Select Teacher</option>
              {staff.map((staffMember) => (
                <option key={staffMember.id} value={staffMember.id}>
                  {staffMember.name}
                </option>
              ))}
            </select>
            <button onClick={handleAddSubject}>Add Subject</button>
            <br />
            <br />
            <table>
              <thead>
                <tr>
                  <th>Subject</th>
                  <th>Abbreviation</th>
                  <th>Teacher</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {subjects.map((subject) => (
                  <tr key={subject.id}>
                    <td>{editableSubject[subject.id]?.name || subject.name}</td>
                    <td>
                      <input
                        type="text"
                        value={
                          editableSubject[subject.id]?.abbreviation ||
                          subject.abbreviation
                        }
                        onChange={(e) =>
                          handleEditSubject(
                            subject.id,
                            "abbreviation",
                            e.target.value
                          )
                        }
                      />
                    </td>
                    <td>
                      <select
                        value={
                          editableSubject[subject.id]?.teacherId ||
                          subject.teacherId
                        }
                        onChange={(e) =>
                          handleEditSubject(
                            subject.id,
                            "teacherId",
                            e.target.value
                          )
                        }
                      >
                        <option value="">Select Teacher</option>
                        {staff.map((staffMember) => (
                          <option key={staffMember.id} value={staffMember.id}>
                            {staffMember.name}
                          </option>
                        ))}
                      </select>
                    </td>
                    <td>
                      <button onClick={() => handleUpdateSubject(subject.id)}>
                        Update
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <br />

          <div>
            <h3>Manage Classes</h3>
            <table>
              <thead>
                <tr>
                  <th>Class ID</th>
                  <th>Class Name</th>
                  <th>Class Teacher</th>
                  <th>Principal</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {classes.map((classData) => (
                  <tr key={classData.id}>
                    <td>{classData.id}</td>
                    <td>
                      <input
                        type="text"
                        value={
                          editableClass[classData.id]?.name || classData.name
                        }
                        onChange={(e) =>
                          handleEditClass(classData.id, "name", e.target.value)
                        }
                      />
                    </td>

                    <td>
                      <select
                        value={
                          editableClass[classData.id]?.class_teacher ||
                          classData.class_teacher
                        }
                        onChange={(e) =>
                          handleEditClass(
                            classData.id,
                            "class_teacher",
                            e.target.value
                          )
                        }
                      >
                        <option value="">Select Teacher</option>
                        {staff.map((staffMember) => (
                          <option key={staffMember.id} value={staffMember.name}>
                            {staffMember.name}
                          </option>
                        ))}
                      </select>
                    </td>

                    <td>
                      <select
                        value={
                          editableClass[classData.id]?.principal ||
                          classData.principal
                        }
                        onChange={(e) =>
                          handleEditClass(
                            classData.id,
                            "principal",
                            e.target.value
                          )
                        }
                      >
                        <option value="">Select Principal</option>
                        {staff.map((staffMember) => (
                          <option key={staffMember.id} value={staffMember.name}>
                            {staffMember.name}
                          </option>
                        ))}
                      </select>
                    </td>

                    <td>
                      <button onClick={() => handleUpdateClass(classData.id)}>
                        Update
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminSettings;
