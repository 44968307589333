import React, { useEffect } from 'react';
import { useLocation } from "react-router-dom";
import { Routes, Route, Navigate } from 'react-router-dom';
import { sharedRoutes } from './SharedRoutes';
import AdminRoutes from './AdminRoutes';
import SponsorRoutes from './SponsorRoutes';
import { Fullscreen } from './components';
import { ProtectedRoute } from './container';
import { useAuth } from './AuthProvider';

function App() {
  Fullscreen();

  const location = useLocation();
  const { isAdmin, isSponsor, loadingUser } = useAuth(); // Use the useAuth hook to get isAdmin and isSponsor status

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <div className="App">
      <Routes basename="/the-watoto-library">
        {sharedRoutes.map(route => (
          <Route
            key={route.path}
            path={route.path}
            element={React.createElement(route.component, { isAdmin, isSponsor })} // Pass isAdmin and isSponsor directly to components
          />
        ))}
        <Route path="*" element={<Navigate to="/" replace />} />
        <Route
          path="/admin/*"
          element={
            <ProtectedRoute>
              {loadingUser ? null : isAdmin ? <AdminRoutes /> : <Navigate to="/" replace />}
            </ProtectedRoute>
          }
        />
        <Route
          path="/sponsor/*"
          element={
            <ProtectedRoute>
              {loadingUser ? null : isSponsor ? <SponsorRoutes /> : <Navigate to="/" replace />}
            </ProtectedRoute>
          }
        />
      </Routes>
    </div>
  );
}

export default App;
