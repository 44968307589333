import React from "react";
import { useAuth } from "../../../AuthProvider";

const Overview = () => {
  const { userData } = useAuth();

  return (
    <div className="row2" id="flex">
      <div className="main">
        <h2>The Watoto Library Intranet</h2>
        <p>
          Logged in as: {userData && userData.name} |{" "}
          {userData && userData.description_1}{" "}
          {userData && userData.description_2
            ? `& ${userData.description_2}`
            : ""}
        </p>
      </div>
    </div>
  );
};

export default Overview;
