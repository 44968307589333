import React, { useEffect, useState } from "react";
import { auth, sendPasswordReset } from "../../firebase"; // Adjust the path as needed
import { signInWithEmailAndPassword } from "firebase/auth";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import "./Auth.css";
import { Navbar, Footer, MyLink } from "../../components";
import Locationscroll from "../../components/Locationscroll/Locationscroll";
import "react-international-phone/style.css";
import PhoneAuth from "./PhoneAuth";
import { useAuth } from "../../AuthProvider";

const Login = ({ isAdmin }) => {
  const { user } = useAuth();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [isProcessing, setIsProcessing] = useState(false);
  const [resetEmailSent, setResetEmailSent] = useState(false);
  const [isResetMode, setIsResetMode] = useState(false); // Track whether in password reset mode
  const navigate = useNavigate(); // Initialize useNavigate

  const [activeOption, setActiveOption] = useState("donor");

  const handleOptionClick = (option) => {
    setActiveOption(option);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setError(""); // Reset error message
    setIsProcessing(true);

    if (!email || typeof email !== "string" || email.trim() === "") {
      setError("Please provide a valid email address.");
      setIsProcessing(false);
      return;
    }

    try {
      if (isResetMode) {
        console.log(email);
        await sendPasswordReset(email.trim());

        setResetEmailSent(true);
        setError(
          "If an account exists for this email, a reset link has been sent."
        );

        setTimeout(() => {
          setError(""); // Clear the message after 5 seconds
          setIsResetMode(!isResetMode); // Return to login mode
        }, 5000);
        setIsProcessing(false);
      } else {
        await signInWithEmailAndPassword(auth, email.trim(), password);
        console.log("Logged in successfully!");
        navigate("/admin"); // Redirect the user to /admin
        setIsProcessing(false);
      }
    } catch (error) {
      console.error(
        isResetMode ? "Password reset failed:" : "Login failed:",
        error.message
      );

      if (isResetMode && error.code === "auth/user-not-found") {
        setError("No account found with this email.");
      } else if (!isResetMode) {
        setError(
          "Failed to log in. Please check your credentials and try again."
        );
      } else {
        setError("Failed to send password reset email. Please try again.");
      }

      setIsProcessing(false);
    }
  };

  const toggleResetMode = () => {
    setIsResetMode(!isResetMode); // Toggle between login and reset mode
    setError(""); // Clear any existing error messages
  };

  if (user) navigate("/sponsorship");

  return (
    <div>
      <Locationscroll />
      <Navbar isAdmin={isAdmin} />
      <div className="row" id="flex">
        <div className="main">
          <div className="login-container">
            <div className="login-container-2 article">
              <h2>Intranet Login</h2>
              <div className="loginoptions">
                <div
                  className={`option ${
                    activeOption === "donor" ? "active" : ""
                  }`}
                  onClick={() => handleOptionClick("donor")}
                >
                  <p>
                    <i className="fa-solid fa-hand-holding-dollar"></i> Sponsor
                  </p>
                </div>
                <div
                  className={`option ${
                    activeOption === "staff-admin" ? "active" : ""
                  }`}
                  onClick={() => handleOptionClick("staff-admin")}
                >
                  <p>
                    <i className="fa-solid fa-child"></i> Staff & Admin
                  </p>
                </div>
              </div>
              <br />
              {activeOption === "donor" && <PhoneAuth />}
              {activeOption === "staff-admin" && (
                <div>
                  <form onSubmit={handleSubmit}>
                    <div>
                      <label>Email</label>
                      <input
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                      />
                    </div>
                    {!isResetMode && (
                      <div>
                        <label>Password</label>
                        <input
                          type="password"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          required
                        />
                      </div>
                    )}
                    {isProcessing ? (
                      <button type="submit" className="login-container-button">
                        {isResetMode ? "Reset Password" : "Login"}{" "}
                        <span className="rolling-loading-animation small blue"></span>
                      </button>
                    ) : (
                      <button type="submit" className="login-container-button">
                        {isResetMode ? "Reset Password" : "Login"}
                      </button>
                    )}
                  </form>
                  <p
                    id="reset"
                    className="backFromAdmin"
                    onClick={toggleResetMode}
                  >
                    {isResetMode ? "Back to Login" : "Forgot password?"}
                  </p>

                  {resetEmailSent && <p>{error}</p>}
                </div>
              )}
              {error && !resetEmailSent && <p className="error">{error}</p>}
              <br />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Login;
