import React from "react";
import "./Innsamling.css";
import { images } from "../../constants";
import arbeidskort from "../../assets/innsamling/Arbeidskort.pdf";
import arbeidsgivere from "../../assets/innsamling/Arbeidsgivere.pdf";
import foresatte from "../../assets/innsamling/Foresatte.pdf";

const Hvordan = () => {
  return (
    <div className="hvordan">
      <div className="content-wrapper">
        <p className="event-description">
          <span className="event-date">
            <b>16. april, 19. april og 5. juni</b>
          </span>{" "}
          samler Skullerud skole inn midler til støtte for The Watoto Academy.
          <br />
          Her kan du lese om gjennomføringen, se og legge ut småjobber.
        </p>
        <p className="action-links">
          <div></div>
          <span className="visually-hidden">For elever:</span>
          <a
            href={arbeidskort}
            download="Arbeidskort.pdf"
            className="download-link"
          >
            Last ned arbeidskort
          </a>
          <span className="visually-hidden">| For arbeidsgivere:</span>
          <a
            href={arbeidsgivere}
            className="employer-link"
            download="Arbeidsgivere.pdf"
          >
            Skriv til arbeidsgivere
          </a>
          <span className="visually-hidden">| For foresatte:</span>
          <a
            href={foresatte}
            className="parent-link"
            download="Til foresatte.pdf"
          >
            Skriv til foresatte
          </a>
        </p>
      </div>
      <h2>
        Støtt Skullerud skole sin innsamling for The Watoto Library & Academy!
      </h2>
      <img src={images.classPhoto} className="classPhoto" />
      <br />
      <br />
      <div className="tab-content">
        <div class="container">
          <div class="half hvordan">
            <h3>Om prosjektet</h3>
            <p>
              Årets innsamlingsprosjekt er i samarbeid med The Watoto
              Library/Academy, og støtter barn i Kenya. I Kiberaslummen bor over
              2 millioner mennesker i det FN definerer som ekstrem fattigdom.
              Familier lever på under 10 kr dagen, og barn legger seg med ett
              måltid om dagen. Helse, vann og sanitærforhold er store
              utfordringer for samfunnet, med hundrevis av folk som deler
              toalett. Skolene er overfylte, med flere enn 120 elever per
              klasse. The Watoto Library har etablert et gratis bibliotek og
              aktivitetssenter, og nylig en gratisskole for flere av Kiberas
              fattigste barn. Dette er barn som uten finansiell støtte ikke
              ville kunnet fortsette skolegangen og begynt i 7. klasse. Barna
              som ikke får fullføre skolen har en dyster framtid foran seg. Med
              støtte fra Skulleruds elever, enkeltpersoner og bedrifter, vil
              barna ha tilgang på kvalitetsutdanning, og et håp for en bedre
              framtid for seg og deres familier. Les mer om Kibera og barna{" "}
              <u>
                <a
                  href="https://thewatotolibrary.org/about/kibera"
                  target="_blank"
                >
                  her
                </a>
              </u>
              .
            </p>
          </div>
          <div className="half hvordan">
            <img src={images.collection} />
          </div>
        </div>
        <br />
        <div className="container">
          <div className="half hvordan third-hvordan">
            <img src={images.collection2} alt="" />
          </div>
          <div class="half hvordan fourth-hvordan">
            <h3>Hvordan kan jeg bidra?</h3>
            <ul>
              <li>Spons et kulturarrangement i lokalsamfunnet</li>
              <li>Støtt et miljøprosjekt hvor elevene rydder plast</li>
              <li>Ansett en elev til å rake i hagen</li>
              <li>Ansett en elev som sosiale medier-sjef for en dag</li>
              <li>Ansett fem elever til å rydde på lageret</li>
              <li>Bruk elever som testgruppe for et produkt eller budskap</li>
              <li>Spons elever med produkter de kan selge på OD-dagen</li>
              <li>Støtt elever som selger produkter eller tjenester</li>
            </ul>
            <p>
              Elever kan rydde i hagen, vaske hjemme, måke snø, lufte kjæledyr,
              passe barn, vaske bil, ha leksehjelp, rydde på lager, hjelpe til i
              butikk, hjelpe til på eldresenter, jobbe som fotballtrener, eller
              ha kakesalg. Mulighetene er mange!
            </p>
          </div>
        </div>
        <div className="container">
          <p>
            <i class="fa-solid fa-circle-info"></i> Les mer om skolen på{" "}
            <a href="https://thewatotoacademy.org/" target="_blank">
              <u>thewatotoacademy.org</u>
            </a>
            , og om organisasjonen på{" "}
            <a href="https://thewatotolibrary.org/" target="_blank">
              <u>thewatotolibrary.org</u>
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Hvordan;
