import React from "react";
import "./Innsamling.css";
import { images } from "../../constants";
import vippsStripeQr from "../../assets/innsamling/VippsStripeQR.pdf";

const Betaling = () => {
  return (
    <div className="tab-content">
      <div className="content-wrapper">
        <p className="event-description">
          På arbeidsdagen jobber elever som ønsker å bidra til årets prosjekt!
          Du kan betale for dagsverk med Vipps, kort eller faktura.
        </p>
      </div>
      <h2>Betaling</h2>
      <div className="container greyBorder">
        <div className="half betaling">
          <h3>Jeg skal betale for en elev</h3>
          <p>
            <b>Vipps:</b> Vipps til #821346
          </p>
          <p>
            <i>Viktig: Merk betalingen med elevens navn i kommentarfeltet.</i>
          </p>
          <br />
          <p>
            <b>Kort:</b>{" "}
            <a href="https://buy.stripe.com/fZe6rk7Jz6CHdj2fYY" target="_blank">
              <u>Klikk her for å betale med kort</u>
            </a>{" "}
            (Stripe)
          </p>
          <p>
            <i>Viktig: Merk betalingen med elevens navn i kommentarfeltet.</i>
          </p>
          <br />
          <p>
            <b>Faktura:</b> Bedrifter som har oppgitt faktura som betalingsmåte
            til eleven(e) vil automatisk få faktura tilsendt.
          </p>
        </div>
        <div className="half betaling">
          <h3>Jeg er elev og vil lage min egen arbeidsplass</h3>

          <p>Få en QR-kode og ta betalt med Vipps eller Apple/Google Pay.</p>
          <i>Viktig: Kjøper må skrive inn ditt/deres navn i kommentarfeltet.</i>
          <br />
          <br />
          <div>
            <a href={vippsStripeQr} download="Vipps og Stripe QR.pdf">
              <u>Last ned QR-plakat for Vipps og Stripe</u>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Betaling;
