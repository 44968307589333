import React, { createContext, useState, useContext, useEffect } from 'react';
import { collection, getDocs, addDoc, setDoc, doc, deleteDoc } from 'firebase/firestore';
import { db } from '../../firebase';

const WhatWeDoContext = createContext();

export const useWhatWeDo = () => useContext(WhatWeDoContext);

export const WhatWeDoProvider = ({ children }) => {
  const [whatWeDo, setWhatWeDo] = useState([]);
  const [whatWeDoLoading, setWhatWeDoLoading] = useState(true);

  useEffect(() => {
    fetchWhatWeDo();
  }, []);

  const fetchWhatWeDo = async () => {
    setWhatWeDoLoading(true);
    const whatWeDoCol = collection(db, 'whatWeDo');
    const whatWeDoSnapshot = await getDocs(whatWeDoCol);
    const whatWeDoList = whatWeDoSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    sortAndSetWhatWeDo(whatWeDoList);
    setWhatWeDoLoading(false);
  };

  const sortAndSetWhatWeDo = (whatWeDoList) => {
    const sortedWhatWeDo = sortWhatWeDo(whatWeDoList);
    setWhatWeDo(sortedWhatWeDo);
  };

  const sortWhatWeDo = (whatWeDoList) => {
    // Implement sorting logic here if needed
    return whatWeDoList;
  };

  const addWhatWeDo = async (whatWeDoData) => {
    try {
      // Use setDoc with doc() to specify the URL as the document name
      await setDoc(doc(db, 'whatWeDo', whatWeDoData.url), whatWeDoData);
      await fetchWhatWeDo(); // Refresh the whatWeDo list after adding a new one
    } catch (error) {
      console.error("Error adding new whatWeDo:", error);
    }
  };

  const deleteWhatWeDo = async (whatWeDoId) => {
    try {
      await deleteDoc(doc(db, "whatWeDo", whatWeDoId));
      await fetchWhatWeDo(); // Refresh the whatWeDo list after deletion
    } catch (error) {
      console.error("Error deleting whatWeDo:", error);
    }
  };

  return (
    <WhatWeDoContext.Provider value={{ whatWeDo, setWhatWeDo, whatWeDoLoading, addWhatWeDo, deleteWhatWeDo }}>
      {children}
    </WhatWeDoContext.Provider>
  );
};
