import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { sharedRoutes } from './SharedRoutes';
import { useAuth } from './AuthProvider'; // Import useAuth hook

function SponsorRoutes() {
  const { userData } = useAuth(); // Get user data from useAuth hook
  const isSponsor = userData && userData.role === 'Sponsor'; // Check if user has sponsor role

  console.log("isSponsor in SponsorRoutes:", isSponsor); // Log isSponsor status in SponsorRoutes

  return (
    <Routes>
      {sharedRoutes.map(route => (
        <Route 
          key={route.path} 
          path={route.path === '/' ? '' : route.path.substring(1)}
          element={React.createElement(route.component, { isSponsor })}
        />
      ))}
    </Routes>
  );
}

export default SponsorRoutes;
