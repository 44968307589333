import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { sharedRoutes } from './SharedRoutes';
import { useAuth } from './AuthProvider'; // Import useAuth hook

function AdminRoutes() {
  const { userData } = useAuth(); // Get user data from useAuth hook
  const isAdmin = userData && userData.role === 'Admin'; // Check if user has admin role

  console.log("isAdmin in AdminRoutes:", isAdmin); // Log isAdmin status in AdminRoutes

  return (
    <Routes>
      {sharedRoutes.map(route => (
        <Route 
          key={route.path} 
          path={route.path === '/' ? '' : route.path.substring(1)}
          element={React.createElement(route.component, { isAdmin })}
        />
      ))}
    </Routes>
  );
}

export default AdminRoutes;
