import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  collection,
  addDoc,
  query,
  where,
  getDocs,
  doc,
  updateDoc,
  serverTimestamp,
  onSnapshot,
} from "firebase/firestore";
import {
  getStorage,
  ref as storageRef,
  uploadBytes,
  getDownloadURL,
} from "firebase/storage"; // Updated Firebase Storage imports
import { db, storage } from "../../../firebase"; // Firebase imports
import { useAuth } from "../../../AuthProvider";
import { v4 as uuidv4 } from "uuid"; // Unique ID for file uploads
import "./Economics.css";

const Economics = () => {
  const { userData, isAdmin } = useAuth(); // Use userData instead of currentUser
  const navigate = useNavigate();
  const [requests, setRequests] = useState([]);
  const [items, setItems] = useState([]);
  const [isNewItem, setIsNewItem] = useState(false); // State to track if a new item is being created
  const [newRequest, setNewRequest] = useState({
    amount: "",
    item: "",
    paidFor: false,
    receipt: null,
    comment: "",
  });

  const [approvedAmounts, setApprovedAmounts] = useState({});
  const [receiptFiles, setReceiptFiles] = useState({});

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // Fetch user's financial requests and available items
  useEffect(() => {
    if (!userData) return; // Make sure userData is available

    // Fetch requests
    let unsubscribeRequests;
    const fetchRequests = async () => {
      try {
        let requestQuery;
        if (isAdmin) {
          requestQuery = collection(db, "economics");
        } else {
          requestQuery = query(
            collection(db, "economics"),
            where("userId", "==", userData.email)
          );
        }

        unsubscribeRequests = onSnapshot(requestQuery, (snapshot) => {
          const fetchedRequests = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setRequests(fetchedRequests);
        });
      } catch (err) {
        console.error("Error fetching requests:", err);
      }
    };

    // Fetch items
    let unsubscribeItems;
    const fetchItems = async () => {
      try {
        const itemsRef = collection(db, "items");
        unsubscribeItems = onSnapshot(itemsRef, (snapshot) => {
          const fetchedItems = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setItems(fetchedItems);
        });
      } catch (err) {
        console.error("Error fetching items:", err);
      }
    };

    fetchRequests();
    fetchItems();

    // Cleanup subscriptions on unmount
    return () => {
      if (unsubscribeRequests) unsubscribeRequests();
      if (unsubscribeItems) unsubscribeItems();
    };
  }, [userData, isAdmin]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (!userData) {
      setError("User not authenticated");
      setLoading(false);
      return;
    }

    const { amount, item, paidFor, receipt, comment } = newRequest;

    if (!amount || !item) {
      setError("Please fill in all required fields");
      setLoading(false);
      return;
    }

    try {
      let receiptUrl = null;

      if (paidFor && receipt) {
        const fileName = `receipts/${uuidv4()}`;
        const receiptRef = storageRef(storage, fileName);
        await uploadBytes(receiptRef, receipt);
        receiptUrl = await getDownloadURL(receiptRef);
      }

      const requestData = {
        userId: userData.email,
        amount: parseFloat(amount),
        item,
        paidFor,
        receiptUrl,
        comment: comment || null, // Include comment if provided
        status: "Awaiting approval",
        createdAt: serverTimestamp(),
      };

      // Add new request to Firestore
      const docRef = await addDoc(collection(db, "economics"), requestData);

      // Immediately add to active requests
      setRequests((prevRequests) => [
        ...prevRequests,
        { id: docRef.id, ...requestData },
      ]);

      // Reset the form
      setNewRequest({
        amount: "",
        item: "",
        paidFor: false,
        receipt: null,
        comment: "",
      });
      setError(null);
    } catch (err) {
      console.error("Error submitting request:", err);
      setError("Error submitting request");
    } finally {
      setLoading(false);
    }
  };

  const handleApproval = async (id, approvedAmount, status) => {
    try {
      const requestRef = doc(db, "economics", id);
      await updateDoc(requestRef, {
        approvedAmount: parseFloat(approvedAmount),
        status,
      });
    } catch (err) {
      console.error("Error approving request:", err);
    }
  };

  const handleReceiptFileChange = (e, requestId) => {
    if (e.target.files[0]) {
      setReceiptFiles({ ...receiptFiles, [requestId]: e.target.files[0] });
    }
  };

  const submitReceipt = async (requestId) => {
    const receipt = receiptFiles[requestId];
    if (!receipt) {
      alert("Please select a receipt file to upload.");
      return;
    }
    try {
      // Upload receipt to Firebase Storage
      const fileName = `receipts/${uuidv4()}`;
      const receiptRef = storageRef(storage, fileName);
      await uploadBytes(receiptRef, receipt);
      const receiptUrl = await getDownloadURL(receiptRef);

      // Update the request in Firestore
      const requestRef = doc(db, "economics", requestId);
      await updateDoc(requestRef, {
        receiptUrl,
        status: "Completed",
      });

      // Remove the receipt file from state
      const newReceiptFiles = { ...receiptFiles };
      delete newReceiptFiles[requestId];
      setReceiptFiles(newReceiptFiles);
    } catch (err) {
      console.error("Error uploading receipt:", err);
      alert("Error uploading receipt.");
    }
  };

  // Separate active and completed requests
  const activeRequests = requests.filter((request) => {
    if (isAdmin) {
      return (
        request.status === "Awaiting approval" || request.status === "Approved"
      );
    } else {
      return (
        request.status === "Approved" || request.status === "Partially approved"
      );
    }
  });

  const completedRequests = requests.filter((request) => {
    if (isAdmin) {
      return (
        request.status !== "Awaiting approval" && request.status !== "Approved"
      );
    } else {
      return request.status === "Completed" || request.status === "Declined";
    }
  });

  const renderUserView = () => (
    <>
      <h3>Create Budget Request</h3>
      <div className="create-request-table">
        <form onSubmit={handleSubmit}>
          <div className="form-row">
            <label>
              Item:
              <input
                type="text"
                placeholder="Enter item name"
                value={newRequest.item}
                onChange={(e) =>
                  setNewRequest({ ...newRequest, item: e.target.value })
                }
                required
              />
            </label>
            <label>
              Amount:
              <input
                type="number"
                value={newRequest.amount}
                onChange={(e) =>
                  setNewRequest({ ...newRequest, amount: e.target.value })
                }
                required
              />
            </label>
            <label>
              Already paid for:
              <input
                type="checkbox"
                checked={newRequest.paidFor}
                onChange={(e) =>
                  setNewRequest({
                    ...newRequest,
                    paidFor: e.target.checked,
                  })
                }
              />
            </label>
            {newRequest.paidFor && (
              <label>
                Upload Receipt:
                <input type="file" onChange={handleReceiptFileChange} />
              </label>
            )}
            <button type="submit" disabled={loading}>
              {loading ? "Submitting..." : "Submit Request"}
            </button>
          </div>

          <label className="full-width">
            Comment (optional):
            <input
              type="text"
              className="full-width"
              placeholder="Add a comment (optional)"
              value={newRequest.comment}
              onChange={(e) =>
                setNewRequest({ ...newRequest, comment: e.target.value })
              }
            />
          </label>

          {error && <p>{error}</p>}
        </form>
      </div>
      <h3>Active Requests</h3>
      {activeRequests.length > 0 && (
        <table className="economics-table">
          <thead>
            <tr>
              <th>Item</th>
              <th>Requested Amount</th>
              <th>Approved Amount</th>
              <th>Status</th>
              <th>Receipt Upload</th>
            </tr>
          </thead>
          <tbody>
            {activeRequests.map((request) => (
              <tr key={request.id}>
                <td>{request.item}</td>
                <td>${request.amount}</td>
                <td>
                  {request.approvedAmount !== undefined
                    ? `$${request.approvedAmount}`
                    : "N/A"}
                </td>
                <td>{request.status}</td>
                <td>
                  {request.receiptUrl ? (
                    <p>Receipt uploaded</p>
                  ) : (
                    <>
                      {request.status === "Partially approved" ||
                      request.status === "Approved" ? (
                        <div>
                          <p>Please upload your receipt.</p>
                          <input
                            type="file"
                            onChange={(e) =>
                              handleReceiptFileChange(e, request.id)
                            }
                          />
                          <button onClick={() => submitReceipt(request.id)}>
                            Upload Receipt
                          </button>
                        </div>
                      ) : (
                        <p>Waiting for approval</p>
                      )}
                    </>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </>
  );

  const renderAdminView = () => (
    <div>
      <h3>Active Requests</h3>
      {activeRequests.length > 0 && (
        <table className="economics-table">
          <thead>
            <tr>
              <th>User</th>
              <th>Item</th>
              <th>Amount Requested</th>
              <th>Approved Amount</th> {/* Added this column */}
              <th>Status</th> {/* Added this column */}
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {activeRequests.map((request) => (
              <tr key={request.id}>
                <td>{request.userId}</td>
                <td>{request.item}</td>
                <td>${request.amount}</td>
                <td>{request.approvedAmount || "N/A"}</td>{" "}
                {/* Approved Amount */}
                <td>{request.status}</td> {/* Status */}
                <td>
                  {request.status === "Approved" ? (
                    <p>Awaiting user receipt</p>
                  ) : (
                    <>
                      <input
                        type="number"
                        placeholder="Approved Amount"
                        value={approvedAmounts[request.id] || ""}
                        onChange={(e) =>
                          setApprovedAmounts({
                            ...approvedAmounts,
                            [request.id]: e.target.value,
                          })
                        }
                      />
                      <button
                        onClick={() =>
                          handleApproval(
                            request.id,
                            approvedAmounts[request.id] || 0,
                            "Partially approved"
                          )
                        }
                      >
                        Partially Approve
                      </button>
                      <button
                        onClick={() =>
                          handleApproval(request.id, request.amount, "Approved")
                        }
                      >
                        Approve Requested Amount
                      </button>
                      <button
                        onClick={() =>
                          handleApproval(request.id, 0, "Declined")
                        }
                      >
                        Decline
                      </button>
                    </>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
      <h3>Completed Requests</h3>
      {completedRequests.length > 0 && (
        <table className="economics-table">
          <thead>
            <tr>
              <th>User</th>
              <th>Item</th>
              <th>Amount Requested</th>
              <th>Approved Amount</th>
              <th>Status</th>
              <th>Receipt</th>
            </tr>
          </thead>
          <tbody>
            {completedRequests.map((request) => (
              <tr key={request.id}>
                <td>{request.userId}</td>
                <td>{request.item}</td>
                <td>${request.amount}</td>
                <td>{request.approvedAmount || "N/A"}</td>
                <td>{request.status}</td>
                <td>
                  {request.receiptUrl ? (
                    <a
                      href={request.receiptUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      View Receipt
                    </a>
                  ) : (
                    <p>No receipt</p>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );

  return (
    <div className="row2" id="flex">
      <div className="main">
        <div className="economics-page">
          <h2>Economics</h2>
          {isAdmin ? renderAdminView() : renderUserView()}
        </div>
      </div>
    </div>
  );
};

export default Economics;
