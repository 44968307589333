import React, { useState, useRef } from "react";
import { Navbar, Footer, Side, MyLink } from "../../components";
import { images } from "../../constants";
import { useNavigate } from "react-router-dom";
import Locationscroll from "../../components/Locationscroll/Locationscroll";
import "./Donate.css";

const Donate = ({ isAdmin }) => {
  console.log("isAdmin in Donate component:", isAdmin);
  const [activeOption, setActiveOption] = useState("one-time");
  const navigate = useNavigate();

  const handleOptionClick = (option) => {
    setActiveOption(option);
  };

  return (
    <div>
      <Locationscroll />
      <Navbar isAdmin={isAdmin} />
      <div className="row" id="flex">
        <Side />
        <div className="main">
          <div className="article">
            <div className="flex-image-text">
              <div>
                <h2>Donate</h2>
                <p>
                  We greatly appreciate any donations coming in that will help
                  better the lives of children in Kibera.
                </p>
                <p>Asante sana - Thank you very much.</p>
                <p>
                  100% of your donation goes directly to its purpose. Our only
                  expenses are transaction fees.
                </p>
              </div>
              <img src={images.kidLooking} className="img" alt="Kid looking" />
            </div>
          </div>

          <br />

          <div className="article donateoptions">
            <div
              className={`option ${
                activeOption === "one-time" ? "active" : ""
              }`}
              onClick={() => handleOptionClick("one-time")}
            >
              <p>
                <i class="fa-solid fa-hand-holding-dollar"></i> Donation
              </p>
            </div>
            {/* <div
              className={`option ${
                activeOption === "our-shop" ? "active" : ""
              }`}
              onClick={() => handleOptionClick("our-shop")}
            >
              <p>
                <i class="fa-solid fa-shop"></i> Our shop
              </p>
            </div> */}

            <div
              className={`option ${
                activeOption === "sponsorship" ? "active" : ""
              }`}
              // onClick={() => handleOptionClick("sponsorship")}
              onClick={() => navigate("/sponsorship")}
            >
              {" "}
              <p>
                <i class="fa-solid fa-child"></i> Sponsor a child
              </p>
            </div>
          </div>
          <br />

          {activeOption === "one-time" && (
            <div>
              <div className="article" id="payment">
                <div className="flex-image-text donate">
                  <div style={{ alignItems: "left" }}>
                    <h3>Vipps</h3>
                    <a
                      className="button-design paypal"
                      id="vippsPc"
                      href={images.scanVipps}
                      target="_blank"
                    >
                      Donate through Vipps
                    </a>
                    <a
                      className="button-design paypal"
                      id="vippsMobile"
                      href="https://qr.vipps.no/28/2/05/031/9z2ImrLtM"
                      target="_blank"
                    >
                      Donate through Vipps
                    </a>
                    <p>
                      Vippsnr: 821346 (The Watoto Youth Community And Library
                      Norway)
                      <br />
                      This solution is only available in Norway.
                    </p>
                  </div>
                  <img
                    src={images.vippsLogo}
                    className="img-nozoom paypal"
                    style={{ marginTop: "-0.3em" }}
                  />
                </div>
              </div>
              <br />
              <div className="article">
                <div className="flex-image-text donate">
                  <div style={{ alignItems: "left", width: "100%" }}>
                    <h3>Stripe</h3>
                    <a
                      className="button-design paypal"
                      id="stripe"
                      href="https://buy.stripe.com/6oEaHA2pf1ina6Q5kl"
                      target="_blank"
                    >
                      Donate through Stripe
                    </a>
                    <p>
                      For one-time or monthly donations with VISA or Mastercard.
                    </p>
                  </div>
                  <img
                    src={images.stripeLogo}
                    className="img-nozoom paypal"
                    style={{ width: "15em" }}
                  />
                </div>
              </div>
              <br />
              <div className="article">
                <div className="flex-image-text donate">
                  <div style={{ alignItems: "left", width: "100%" }}>
                    <h3>PayPal</h3>
                    <a
                      className="button-design paypal"
                      href="https://www.paypal.com/donate/?hosted_button_id=F9WFK8JFMH49E"
                      target="_blank"
                    >
                      Donate through PayPal
                    </a>
                    <p>
                      For one-time or monthly donations with VISA or Mastercard.
                    </p>
                  </div>
                  <img
                    src={images.paypalLogo}
                    className="img-nozoom paypal"
                    style={{ width: "15em", marginBottom: "0.8em" }}
                  />
                </div>
              </div>
              <br />
              <div className="article">
                <h3>Bank Transfer</h3>
                <p>
                  Name: The Watoto Youth Community And Library Norway
                  <br />
                  Account number: 1506 93 25022
                </p>
                <p>
                  Electronic/paper IBAN: NO55 1506 9325 022
                  <br />
                  Address: DNB Bank ASA, Postboks 1600 Sentrum, 0021 Oslo
                  <br />
                  BIC: DNBANOKKXXX | Swift: DNBANOKK
                </p>
              </div>
            </div>
          )}

          {activeOption === "our-shop" && (
            <div className="article">
              <h3>Our shop</h3>
              <p>
                Lorem ipsum dolor sit, amet consectetur adipisicing elit. Culpa
                eum eligendi aspernatur perferendis at ipsum asperiores voluptas
                reprehenderit voluptate quam. At eveniet debitis amet quia
                deserunt aut in asperiores mollitia?
              </p>
            </div>
          )}

          {activeOption === "sponsorship" && (
            <div className="article">
              <h3>Sponsor a child</h3>
              <p>
                For only $1 / 10 kr per day, you can sponsor a child with ...
              </p>
              <p>
                Currently, only residents in Norway and USA can sponsor a child.{" "}
                <br />
                To read our kids' stories and backgrounds, kindly{" "}
                <MyLink to="/login">
                  <button className="button">log in</button>
                </MyLink>{" "}
                to our platform with one of the options below.
              </p>
              <p></p>
            </div>
          )}

          <br />

          <div className="article" id="legal">
            <h3>Legal</h3>
            <p>
              The Watoto Library is registered in Kenya as a CBO, org. nr.
              KBR/CBO/5/4/2023(439).
            </p>

            <p>
              The Watoto Library is registered in Norway under{" "}
              <u>
                <a
                  href="https://w2.brreg.no/enhet/sok/detalj.jsp?orgnr=931464817"
                  target="_blank"
                >
                  The Watoto Youth Community And Library Norway
                </a>
              </u>
              , org. nr. 931 464 817.
            </p>

            <p>
              For Norwegian sales terms,{" "}
              <MyLink to="termsofsale">
                <u>
                  <a>click here</a>
                </u>
              </MyLink>
              .
            </p>
            <p>
              To end monthly, recurring payments, kindly get in touch with us at{" "}
              <u>
                <a href="mailto:magnus@thewatotolibrary.org">
                  magnus@thewatotolibrary.org
                </a>
              </u>
              .
              <br />
              All recurring payments are of course not binding and can be
              terminated at your discretion.
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Donate;
