import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './articles.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { ArticlesProvider } from './container/Articles/ArticlesContext';
import { WhatWeDoProvider } from './container/WhatWeDo/WhatWeDoContext';
import { UsersProvider } from './container/Auth/UsersContext';
import { AuthProvider } from './AuthProvider'; // Import the AuthProvider

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
    <AuthProvider> 
      <UsersProvider>
          <ArticlesProvider>
            <WhatWeDoProvider>
              <App />
            </WhatWeDoProvider>
          </ArticlesProvider> 
        </UsersProvider>
      </AuthProvider>
    </BrowserRouter>
  </React.StrictMode>
);
