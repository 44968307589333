import React, { useEffect, useState } from "react";
import "./Innsamling.css";
import { db } from "../../firebase"; // Adjust this path to where you export your Firestore instance
import {
  collection,
  getDocs,
  query,
  where,
  updateDoc,
  doc,
} from "firebase/firestore";

const Stillinger = ({ isAdmin }) => {
  const [records, setRecords] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [deletionCodeInput, setDeletionCodeInput] = useState("");
  const [triggerUpdate, setTriggerUpdate] = useState(false); // Added trigger state

  useEffect(() => {
    const fetchRecords = async () => {
      setIsLoading(true);
      try {
        const querySnapshot = await getDocs(collection(db, "innsamlinger"));
        const recordsData = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        recordsData.sort((a, b) => {
          if (a.status === "denied" && b.status !== "denied") return 1;
          if (a.status !== "denied" && b.status === "denied") return -1;
          return 0;
        });

        setRecords(recordsData);
      } catch (error) {
        console.error("Error fetching documents: ", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchRecords();
  }, [triggerUpdate]); // Added triggerUpdate as a dependency

  // Handler for updating the deletion code input state
  const handleDeletionCodeInputChange = (event) => {
    setDeletionCodeInput(event.target.value);
  };

  // Function to deny a record based on the deletion code
  const denyRecordWithDeletionCode = async () => {
    if (deletionCodeInput.trim() === "") {
      alert("Skriv inn slettekoden din.");
      return;
    }

    setIsLoading(true);
    const q = query(
      collection(db, "innsamlinger"),
      where("deletionCode", "==", deletionCodeInput)
    );
    const querySnapshot = await getDocs(q);

    if (querySnapshot.docs.length === 0) {
      alert("Ingen utlysninger funnet med slettekoden.");
      setIsLoading(false);
      return;
    }

    const docRef = doc(db, "innsamlinger", querySnapshot.docs[0].id);
    try {
      await updateDoc(docRef, { status: "denied" });
      alert("Utlysning slettet. Tusen takk for ditt bidrag!");
      setTriggerUpdate(!triggerUpdate);
      setDeletionCodeInput("");
    } catch (error) {
      console.error("Error updating document: ", error);
      alert(
        "Kunne ikke slette utlysning. Prøv igjen eller kontakt på magnus.heide@osloskolen.no"
      );
    } finally {
      setIsLoading(false);
    }
  };

  const approveRecord = async (id) => {
    const recordRef = doc(db, "innsamlinger", id);
    try {
      await updateDoc(recordRef, {
        status: "approved",
      });
      console.log("Record approved successfully");
      setTriggerUpdate(!triggerUpdate); // Toggle to trigger useEffect
    } catch (error) {
      console.error("Error approving record: ", error);
    }
  };

  const denyRecord = async (id) => {
    const recordRef = doc(db, "innsamlinger", id);
    try {
      await updateDoc(recordRef, {
        status: "denied",
      });
      console.log("Record denied successfully");
      setTriggerUpdate(!triggerUpdate); // Toggle to trigger useEffect
    } catch (error) {
      console.error("Error denying record: ", error);
    }
  };

  return (
    <div className="tab-content">
      <div className="content-wrapper">
        <p className="event-description">
          Har du funnet noen til jobben din? Skriv inn slettekoden du valgte da
          du opprettet stillingen:{" "}
          <input
            type="text"
            className="epost-input"
            value={deletionCodeInput}
            onChange={handleDeletionCodeInputChange}
          />
          <button onClick={denyRecordWithDeletionCode}>Slett jobb</button>
        </p>
      </div>
      <h2>Ledige jobber</h2>
      <div className="all-records greyBorder">
        {isLoading ? (
          <div>
            <br />
            <p>Laster...</p>
          </div>
        ) : (
          (() => {
            // Filter records based on isAdmin flag and status
            const filteredRecords = records.filter(
              (record) => isAdmin || record.status !== "denied"
            );

            // Check if filteredRecords array is empty
            if (filteredRecords.length === 0) {
              return (
                <div>
                  <br />
                  <p>Ingen jobber utlyst for øyeblikket.</p>
                </div>
              );
            } else {
              return filteredRecords.map(
                ({
                  id,
                  title,
                  location,
                  students,
                  description,
                  email,
                  phone,
                  status,
                }) => (
                  <div
                    key={id}
                    className={`record ${
                      status === "denied" && isAdmin ? "denied-record" : ""
                    }`}
                  >
                    <p>
                      <b>{title}</b> | <i className="fa-solid fa-house"></i>{" "}
                      {location}
                    </p>
                    <p>
                      {students} {students === 1 ? "elev" : "elever"}
                    </p>
                    <p className="beskrivelse">Jobb: {description}</p>
                    <p>
                      <i className="fa-solid fa-envelope"></i> {email}
                    </p>
                    <p>
                      <i className="fa-solid fa-phone"></i> {phone}
                    </p>
                    {status === "needs-approval" && isAdmin && (
                      <div className="approve-buttons">
                        <button
                          onClick={() => approveRecord(id)}
                          className="approve-btn"
                        >
                          <i className="fa-solid fa-check"></i> Approve
                        </button>
                        <button
                          onClick={() => denyRecord(id)}
                          className="deny-btn"
                        >
                          <i className="fa-solid fa-xmark"></i> Deny
                        </button>
                      </div>
                    )}
                  </div>
                )
              );
            }
          })()
        )}
      </div>
    </div>
  );
};

export default Stillinger;
