// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore, doc, updateDoc } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
import { getAuth, sendPasswordResetEmail, signInWithEmailAndPassword } from "firebase/auth"; 

const firebaseConfig = {
    apiKey: "AIzaSyDPe6RNj1V9dMQUHQzIvyI9xz3HgUEyyDs",
    authDomain: "the-watoto-library-792a3.firebaseapp.com",
    projectId: "the-watoto-library-792a3",
    storageBucket: "the-watoto-library-792a3.appspot.com",
    messagingSenderId: "58473501579",
    appId: "1:58473501579:web:b9aa0ccd91f9ee957b558e",
    measurementId: "G-62JB3PC1YX"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const storage = getStorage(app)
const analytics = getAnalytics(app);
const db = getFirestore(app);
const auth = getAuth(app); // Initialize Firebase Authentication

export { app, analytics, db, auth, storage, doc, updateDoc};

// Example usage for signing in a user
const signIn = async (email, password) => {
    try {
        const userCredential = await signInWithEmailAndPassword(auth, email, password);
        // User is signed in
        console.log('User signed in:', userCredential.user);
    } catch (error) {
        console.error('Error signing in:', error);
    }
};

const sendPasswordReset = async (email) => {
    try {
        await sendPasswordResetEmail(auth, email);
        console.log('Password reset email sent!');
    } catch (error) {
        console.error('Error sending password reset email:', error.message);
    }
};


export { signIn, sendPasswordReset };
