import React, { useState, useEffect } from "react";
import {
  getFirestore,
  collection,
  getDocs,
  doc,
  updateDoc,
} from "firebase/firestore";
import Locationscroll from "../../../components/Locationscroll/Locationscroll";
import { useAuth } from "../../../AuthProvider";
import "./StudentAttendance.css"; // Add your custom CSS

const daysOfWeek = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];
const attendanceOptions = ["Present", "Not Present", "Excused", "Late"];

const StudentAttendance = () => {
  const { userData } = useAuth();
  const [loading, setLoading] = useState(true);
  const [studentClasses, setStudentClasses] = useState([]);
  const [selectedClass, setSelectedClass] = useState("");
  const [students, setStudents] = useState([]);
  const [attendance, setAttendance] = useState({});
  const [currentWeek, setCurrentWeek] = useState(new Date());
  const [subjects, setSubjects] = useState([]);
  const [lessons, setLessons] = useState({}); // Store lessons by day
  const [newLesson, setNewLesson] = useState({
    subjectId: "",
    day: "",
    startTime: "",
    endTime: "",
  });
  const [markAllPresent, setMarkAllPresent] = useState(true); // Default to true for marking all present
  const [showDropdowns, setShowDropdowns] = useState({});
  const [confirmedDays, setConfirmedDays] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      const db = getFirestore();

      // Fetch student classes
      const studentsCollection = collection(db, "students");
      const classSnapshot = await getDocs(studentsCollection);
      const classes = classSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setStudentClasses(classes);

      // Fetch subjects
      const subjectsCollection = collection(db, "subjects");
      const subjectsSnapshot = await getDocs(subjectsCollection);
      const subjectsData = subjectsSnapshot.docs.map((doc) => ({
        id: doc.id,
        name: doc.data().name,
        abbreviation: doc.data().abbreviation,
        teacherId: doc.data().teacherId,
      }));
      setSubjects(subjectsData);
      setLoading(false);
    };

    fetchData();
  }, []);

  useEffect(() => {
    const savedClass = localStorage.getItem("selectedClass");
    if (savedClass) {
      setSelectedClass(savedClass);
    }
  }, []);

  // New useEffect to call fetchStudentsForClass after both studentClasses and selectedClass are available
  useEffect(() => {
    if (selectedClass && studentClasses.length > 0) {
      fetchStudentsForClass(selectedClass);
    }
  }, [selectedClass, studentClasses]); // Watch both selectedClass and studentClasses

  const fetchStudentsForClass = async (classId) => {
    const selectedClassData = studentClasses.find(
      (studentClass) => studentClass.id === classId
    );

    // Check if the selected class data exists
    if (!selectedClassData) {
      console.error("Selected class data not found.");
      return;
    }

    setStudents(selectedClassData.students_list || []);

    const initialAttendance = {};
    selectedClassData.students_list.forEach((student) => {
      initialAttendance[student.id] = student.attendance || {};
    });
    setAttendance(initialAttendance);
    setLessons(selectedClassData.lessons || {});
    setConfirmedDays(selectedClassData.confirmedDays || {});
  };

  const handleAttendanceChange = async (studentId, day, lessonId, status) => {
    const updatedAttendance = {
      ...attendance,
      [studentId]: {
        ...attendance[studentId],
        [day]: {
          ...attendance[studentId]?.[day],
          [lessonId]: status,
        },
      },
    };

    setAttendance(updatedAttendance);

    setShowDropdowns((prev) => ({
      ...prev,
      [`${studentId}-${lessonId}`]: false,
    }));

    // Automatically save the updated attendance
    const db = getFirestore();
    const classDocRef = doc(db, "students", selectedClass);

    const updatedStudents = students.map((student) => ({
      ...student,
      attendance: {
        ...student.attendance,
        ...updatedAttendance[student.id],
      },
    }));

    await updateDoc(classDocRef, {
      students_list: updatedStudents,
    });
  };

  const handleToggleDropdown = (studentId, lessonId, day) => {
    // Only allow toggling the dropdown if the day is not confirmed
    if (!confirmedDays[day]) {
      setShowDropdowns((prev) => ({
        ...prev,
        [`${studentId}-${lessonId}`]: !prev[`${studentId}-${lessonId}`],
      }));
    }
  };

  const getIcon = (status) => {
    switch (status) {
      case "Present":
        return "🟢";
      case "Not Present":
        return "❌";
      case "Excused":
        return "🟡";
      case "Late":
        return "⏰";
      default:
        return "";
    }
  };

  const handlePreviousWeek = () => {
    const previousWeek = new Date(currentWeek);
    previousWeek.setDate(previousWeek.getDate() - 7);

    const previousWeekNumber = getWeekNumber(previousWeek);

    if (previousWeekNumber >= 37) {
      setCurrentWeek(previousWeek);
    } else {
      console.log("Cannot go back beyond week 37");
    }
  };

  const handleNextWeek = () => {
    const nextWeek = new Date(currentWeek);
    nextWeek.setDate(nextWeek.getDate() + 7);

    const today = new Date();
    const startOfCurrentWeek = new Date(today);
    startOfCurrentWeek.setDate(today.getDate() - today.getDay() + 1); // Start of this week (Monday)

    if (nextWeek <= startOfCurrentWeek) {
      setCurrentWeek(nextWeek);
    }
  };

  const isNextWeekDisabled = () => {
    const today = new Date();
    const startOfCurrentWeek = new Date(today);
    startOfCurrentWeek.setDate(today.getDate() - today.getDay() + 1); // Start of this week (Monday)

    return currentWeek >= startOfCurrentWeek;
  };

  const getStartOfWeek = () => {
    const startOfWeek = new Date(currentWeek);
    startOfWeek.setDate(startOfWeek.getDate() - startOfWeek.getDay() + 1); // Monday as start of the week
    return startOfWeek;
  };

  const getWeekNumber = (date) => {
    const firstDayOfYear = new Date(date.getFullYear(), 0, 1);
    const daysOffset = (firstDayOfYear.getDay() + 6) % 7; // Monday as the first day of the week
    const diff = date - firstDayOfYear + 24 * 60 * 60 * 1000 * daysOffset;
    const weekNumber = Math.ceil(diff / (7 * 24 * 60 * 60 * 1000));
    return weekNumber;
  };

  const handleLessonCreation = async () => {
    const { day, subjectId, startTime, endTime } = newLesson;

    if (!day || !subjectId || !startTime || !endTime) {
      alert(
        "Please fill in all the required fields: day, subject, start time, and end time."
      );
      return;
    }

    const isConfirmed = window.confirm(
      `Are you sure you want to create a new lesson on ${day} from ${startTime} to ${endTime}? You will need to contact management to edit details.`
    );

    // If user cancels, do nothing
    if (!isConfirmed) {
      return;
    }

    const lessonId = `${subjectId}-${startTime}-${endTime}`;

    // Add the lesson
    const updatedLessons = {
      ...lessons,
      [day]: [
        ...(lessons[day] || []),
        {
          lessonId,
          subjectId,
          startTime,
          endTime,
        },
      ],
    };
    setLessons(updatedLessons);

    // Automatically set all students to "Present" by default
    const updatedAttendance = { ...attendance };
    students.forEach((student) => {
      updatedAttendance[student.id] = {
        ...updatedAttendance[student.id],
        [day]: {
          ...updatedAttendance[student.id]?.[day],
          [lessonId]: "Present", // Set default status as "Present"
        },
      };
    });
    setAttendance(updatedAttendance);

    // Save updated attendance and lessons to Firestore
    const db = getFirestore();
    const classDocRef = doc(db, "students", selectedClass);
    const updatedStudents = students.map((student) => ({
      ...student,
      attendance: {
        ...student.attendance,
        ...updatedAttendance[student.id],
      },
    }));

    await updateDoc(classDocRef, {
      students_list: updatedStudents,
      lessons: updatedLessons,
    });

    // Clear the new lesson fields
    setNewLesson({ subjectId: "", day: "", startTime: "", endTime: "" });
  };

  const handleConfirmDay = async (day) => {
    const dayLessons = lessons[day] || [];
    const subjectNames = dayLessons
      .map((lesson) => {
        const subject = subjects.find((sub) => sub.id === lesson.subjectId);
        return subject ? subject.name : "";
      })
      .filter((name) => name) // Remove any empty subject names
      .join(", ");

    if (
      window.confirm(
        `Confirm that the subjects for today were: ${subjectNames}. \n \n Once confirmed, you will need to contact management to change attendance.`
      )
    ) {
      const confirmedBy = userData.name || userData.email || "Unknown User"; // Fallback to "Unknown User" if neither is available
      const confirmedAt = new Date(); // Store the actual Date object

      const updatedConfirmedDays = {
        ...confirmedDays,
        [day]: {
          confirmedBy,
          confirmedAt,
        },
      };
      setConfirmedDays(updatedConfirmedDays);

      // Save confirmed day with additional info to Firestore
      const db = getFirestore();
      const classDocRef = doc(db, "students", selectedClass);

      await updateDoc(classDocRef, {
        confirmedDays: updatedConfirmedDays,
      });
    }
  };

  const renderTableBody = () => {
    return students.map((student) => (
      <tr key={student.id}>
        <td>{student.name}</td>
        {daysOfWeek.map((day, index) => {
          const dayDate = new Date(currentWeek);
          dayDate.setDate(dayDate.getDate() - dayDate.getDay() + 1 + index);
          const formattedDate = dayDate.toISOString().slice(0, 10);
          const isDayConfirmed = !!confirmedDays[formattedDate]; // Check if the day is confirmed

          return (
            <td key={day}>
              {lessons[formattedDate]?.map((lesson) => {
                const { lessonId, subjectId } = lesson;
                const subject = subjects.find((sub) => sub.id === subjectId);
                const attendanceStatus =
                  attendance[student.id]?.[formattedDate]?.[lessonId] || "";

                return (
                  <div
                    key={lessonId}
                    className="small-font"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <span
                      onClick={() =>
                        handleToggleDropdown(
                          student.id,
                          lessonId,
                          formattedDate
                        )
                      }
                      style={{
                        color: isDayConfirmed ? "gray" : "inherit",
                      }}
                    >
                      {subject?.abbreviation}
                    </span>
                    <span
                      onClick={() =>
                        handleToggleDropdown(
                          student.id,
                          lessonId,
                          formattedDate
                        )
                      }
                    >
                      {getIcon(attendanceStatus)}
                    </span>

                    {!isDayConfirmed &&
                      showDropdowns[`${student.id}-${lessonId}`] && (
                        <select
                          value={attendanceStatus}
                          onChange={(e) =>
                            handleAttendanceChange(
                              student.id,
                              formattedDate,
                              lessonId,
                              e.target.value
                            )
                          }
                        >
                          <option value="" disabled>
                            Select status
                          </option>
                          {attendanceOptions.map((option) => (
                            <option key={option} value={option}>
                              {option}
                            </option>
                          ))}
                        </select>
                      )}
                  </div>
                );
              })}
            </td>
          );
        })}
      </tr>
    ));
  };

  const renderTodayLessons = () => {
    return (
      <tr className="lightbluebg">
        <td>Today's Lessons</td>
        {daysOfWeek.map((day, index) => {
          const dayDate = new Date(currentWeek);
          dayDate.setDate(dayDate.getDate() - dayDate.getDay() + 1 + index);
          const formattedDate = dayDate.toISOString().slice(0, 10);

          // Sort lessons by startTime
          const sortedLessons = (lessons[formattedDate] || []).sort((a, b) => {
            const timeA = a.startTime.replace(":", "");
            const timeB = b.startTime.replace(":", "");
            return timeA.localeCompare(timeB); // Sort by startTime
          });

          return (
            <td key={day}>
              {sortedLessons.map((lesson) => {
                const { startTime, endTime, subjectId } = lesson;
                const subject = subjects.find((sub) => sub.id === subjectId);

                return (
                  <div key={lesson.lessonId} className="todays-lessons">
                    {startTime}-{endTime} {subject?.abbreviation}
                  </div>
                );
              })}
            </td>
          );
        })}
      </tr>
    );
  };

  const renderView = () => {
    return (
      <div>
        {" "}
        <div className="class-selector">
          <label>Select Class: </label>
          <select
            value={selectedClass}
            onChange={(e) => {
              setSelectedClass(e.target.value);
              localStorage.setItem("selectedClass", e.target.value);
              fetchStudentsForClass(e.target.value);
            }}
          >
            <option value="">Select Class</option>
            {studentClasses.map((studentClass) => (
              <option key={studentClass.id} value={studentClass.id}>
                {studentClass.name}
              </option>
            ))}
          </select>
        </div>
        {selectedClass && (
          <div>
            <h3>Create Lesson</h3>
            <div className="create-lesson-parent">
              Day:{" "}
              <select
                value={newLesson.day}
                onChange={(e) =>
                  setNewLesson({ ...newLesson, day: e.target.value })
                }
              >
                <option value="" disabled>
                  Select Day
                </option>
                {daysOfWeek.map((day, index) => {
                  const dayDate = new Date(currentWeek);
                  dayDate.setDate(
                    dayDate.getDate() - dayDate.getDay() + 1 + index
                  );
                  const formattedDate = dayDate.toISOString().slice(0, 10);

                  // Check if the day is confirmed
                  const isConfirmed = !!confirmedDays[formattedDate];

                  return (
                    <option
                      key={day}
                      value={formattedDate}
                      disabled={isConfirmed}
                    >
                      {day} {isConfirmed ? "(confirmed)" : ""}
                    </option>
                  );
                })}
              </select>
              Subject:
              <select
                value={newLesson.subjectId}
                onChange={(e) =>
                  setNewLesson({ ...newLesson, subjectId: e.target.value })
                }
              >
                <option value="" disabled>
                  Select Subject
                </option>
                {subjects.map((subject) => (
                  <option key={subject.id} value={subject.id}>
                    {subject.name}
                  </option>
                ))}
              </select>
              From:
              <input
                type="time"
                className="student-attendance-time-input"
                value={newLesson.startTime}
                onChange={(e) =>
                  setNewLesson({ ...newLesson, startTime: e.target.value })
                }
              />
              To:
              <input
                type="time"
                className="student-attendance-time-input"
                value={newLesson.endTime}
                onChange={(e) =>
                  setNewLesson({ ...newLesson, endTime: e.target.value })
                }
              />
              <label>
                <input
                  type="checkbox"
                  checked={markAllPresent}
                  onChange={() => setMarkAllPresent(!markAllPresent)}
                />
                Mark all students as present
              </label>
              <button onClick={handleLessonCreation}>Add Lesson</button>
            </div>
          </div>
        )}
        <br />
        {selectedClass && (
          <div>
            <div className="week-navigation">
              <button onClick={handlePreviousWeek}>Previous Week</button>
              <span> Week {getWeekNumber(getStartOfWeek())} </span>
              <button onClick={handleNextWeek} disabled={isNextWeekDisabled()}>
                Next Week
              </button>
            </div>
          </div>
        )}
        {selectedClass && students.length > 0 && (
          <div className="student-attendance-table-parent">
            {selectedClass && students.length > 0 && (
              <div className="student-attendance-table">
                <h3>Attendance for Week {getWeekNumber(getStartOfWeek())}</h3>
                <table className="student-attendance-table">
                  <thead>
                    <tr className="lightbluebg">
                      <th>Week {getWeekNumber(getStartOfWeek())}</th>
                      {daysOfWeek.map((day) => (
                        <th key={day}>{day}</th>
                      ))}
                    </tr>
                    <tr>
                      <th>
                        <span className="small-font">Confirmed by</span>
                      </th>
                      {daysOfWeek.map((day, index) => {
                        const dayDate = new Date(currentWeek);
                        dayDate.setDate(
                          dayDate.getDate() - dayDate.getDay() + 1 + index
                        );
                        const formattedDate = dayDate
                          .toISOString()
                          .slice(0, 10);

                        const confirmationInfo = confirmedDays[formattedDate];

                        return (
                          <th key={day}>
                            {!confirmationInfo ? (
                              <button
                                onClick={() => handleConfirmDay(formattedDate)}
                                className="confirm-button"
                                disabled={!!confirmationInfo} // Disable if already confirmed
                              >
                                Confirm
                              </button>
                            ) : (
                              <div className="small-font">
                                <span>{confirmationInfo.confirmedBy}</span>
                                <br />
                                <span>
                                  {confirmationInfo.confirmedAt?.toDate
                                    ? confirmationInfo.confirmedAt
                                        .toDate()
                                        .toLocaleString()
                                    : new Date(
                                        confirmationInfo.confirmedAt
                                      ).toLocaleString()}
                                </span>
                              </div>
                            )}
                          </th>
                        );
                      })}
                    </tr>
                  </thead>
                  <tbody>
                    {renderTodayLessons()}
                    {renderTableBody()}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        )}
      </div>
    );
  };

  return (
    <>
      <div>
        <Locationscroll />
        <div className="attendance-page">
          <h2>Weekly Student Attendance</h2>
          {loading ? <div>Loading...</div> : renderView()}
        </div>
      </div>
    </>
  );
};

export default StudentAttendance;
