import React, { useState, useEffect } from "react";

const Loading = ({ loadingText }) => {
  const [dots, setDots] = useState("");

  useEffect(() => {
    const interval = setInterval(() => {
      setDots((prevDots) => (prevDots.length < 1 ? prevDots + "." : ""));
    }, 500); // Change the dot every 500ms

    return () => clearInterval(interval);
  }, []);

  return (
    <span className="loading">
      {loadingText}..{dots}
    </span>
  );
};

export default Loading;
