import React, { useState, useEffect } from "react";
import {
  getFirestore,
  collection,
  getDocs,
  doc,
  updateDoc,
} from "firebase/firestore";
import Locationscroll from "../../../components/Locationscroll/Locationscroll";
import { useAuth } from "../../../AuthProvider";
import "./StudentGrades.css";

const StudentGrades = () => {
  const { isAdmin, userData } = useAuth();
  const [loading, setLoading] = useState(true);
  const [studentClasses, setStudentClasses] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [exams, setExams] = useState([]);
  const [selectedExam, setSelectedExam] = useState(""); // Store the selected exam
  const [selectedSubjects, setSelectedSubjects] = useState([]); // Store subjects selected for the exam
  const [subjectsSaved, setSubjectsSaved] = useState(false); // Track if subjects are saved
  const [grades, setGrades] = useState({});
  const [staff, setStaff] = useState([]); // List of all staff
  const [examSpecificTeachers, setExamSpecificTeachers] = useState({}); // Exam-specific teachers
  const [sortedStudentClasses, setSortedStudentClasses] = useState([]); // Store sorted student classes
  const [sortedSubjects, setSortedSubjects] = useState([]); // Store sorted subjects
  const [showSubjectSelection, setShowSubjectSelection] = useState(true);
  const [saveStatus, setSaveStatus] = useState({}); // Track save status for each student

  useEffect(() => {
    const fetchData = async () => {
      const db = getFirestore();

      // Fetch student classes
      const studentsCollection = collection(db, "students");
      const classSnapshot = await getDocs(studentsCollection);
      const classes = classSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setStudentClasses(classes);
      setSortedStudentClasses(classes);

      // Fetch subjects
      const subjectsCollection = collection(db, "subjects");
      const subjectsSnapshot = await getDocs(subjectsCollection);
      const subjectsData = subjectsSnapshot.docs.map((doc) => ({
        id: doc.id,
        name: doc.data().name,
        abbreviation: doc.data().abbreviation,
        teacherId: doc.data().teacherId,
      }));

      setSubjects(subjectsData);

      // Fetch exams and match with student classes
      const examsCollection = collection(db, "exams");
      const examsSnapshot = await getDocs(examsCollection);
      const examsData = examsSnapshot.docs.map((doc) => {
        const classId = doc.data().classId;

        // Find the corresponding student class by comparing classId with student classes
        const matchingClass = classes.find(
          (studentClass) => studentClass.id === classId
        );
        const studentClassName = matchingClass
          ? matchingClass.name
          : "Unknown Class";

        return {
          id: doc.id,
          name: doc.data().name,
          classId: doc.data().classId,
          studentClassName,
          subjects: doc.data().subjects || [],
          teachers: doc.data().teachers || {},
        };
      });
      setExams(examsData);

      // Fetch staff
      const staffCollection = collection(db, "staff");
      const staffSnapshot = await getDocs(staffCollection);
      const staffData = staffSnapshot.docs.map((doc) => ({
        id: doc.id,
        name: doc.data().name,
      }));
      setStaff(staffData);
      setLoading(false);

      // Set default exam from local storage, if available
      const savedExam = localStorage.getItem("selectedExam");
      const initialExam = savedExam
        ? examsData.find((exam) => exam.name === savedExam)
        : examsData[0];

      if (initialExam) {
        setSelectedExam(initialExam.name);
        setSelectedSubjects(initialExam.subjects || []);
        setSortedSubjects(initialExam.subjects || []);
        setExamSpecificTeachers(initialExam.teachers || {});
        setSubjectsSaved(initialExam.subjects.length > 0);
        setShowSubjectSelection(initialExam.subjects.length <= 1);
        fetchGradesForExam(initialExam.name, classes);
      }
    };

    fetchData();
  }, []);

  // Handle toggle for subject selection visibility
  const toggleSubjectSelection = () => {
    setShowSubjectSelection((prev) => !prev);
  };

  const fetchGradesForExam = (examName, classes) => {
    const initialGrades = {};
    classes.forEach((studentClass) => {
      studentClass.students_list.forEach((student) => {
        if (student.grades && student.grades[examName]) {
          initialGrades[studentClass.id] = {
            ...initialGrades[studentClass.id],
            [student.id]: student.grades[examName],
          };
        }
      });
    });
    setGrades(initialGrades);
  };

  const handleGradeChange = (classId, studentId, subject, score) => {
    const numericScore = Number(score);
    setGrades((prevGrades) => ({
      ...prevGrades,
      [classId]: {
        ...prevGrades[classId],
        [studentId]: {
          ...(prevGrades[classId]?.[studentId] || {}), // Initialize if undefined
          [subject]: numericScore,
        },
      },
    }));
  };

  const handleSaveGrades = async (classId, studentId) => {
    const db = getFirestore();
    const classDocRef = doc(db, "students", classId);
    const studentClass = studentClasses.find((c) => c.id === classId);
    const student = studentClass.students_list.find((s) => s.id === studentId);

    if (!student.grades) {
      student.grades = {};
    }

    // Update student's grades with the grades from state for the selected exam
    student.grades[selectedExam] = {
      ...student.grades[selectedExam],
      ...grades[classId][studentId],
    };

    await updateDoc(classDocRef, {
      students_list: studentClass.students_list,
    });
  };

  const handleSubjectSelection = (subjectId) => {
    setSelectedSubjects((prevSubjects) => {
      if (prevSubjects.includes(subjectId)) {
        return prevSubjects.filter((id) => id !== subjectId); // Remove the subject if already selected
      }
      return [...prevSubjects, subjectId]; // Add the subject if not selected
    });
  };

  const handleSaveExamSubjects = async (examId) => {
    const db = getFirestore();
    const examDocRef = doc(db, "exams", examId);

    // Update the exam with the selected subjects and teachers
    await updateDoc(examDocRef, {
      subjects: selectedSubjects,
      teachers: examSpecificTeachers, // Save the exam-specific teachers
    });

    // Directly update the state for the currently selected exam
    setExams((prevExams) =>
      prevExams.map((exam) =>
        exam.id === examId
          ? {
              ...exam,
              subjects: selectedSubjects,
              teachers: examSpecificTeachers,
            }
          : exam
      )
    );

    // Refetch the updated exam data and update selected subjects
    const updatedExamSnapshot = await getDocs(collection(db, "exams"));
    const updatedExam = updatedExamSnapshot.docs.find(
      (doc) => doc.id === examId
    );
    const updatedSubjects = updatedExam.data().subjects || [];
    setSelectedSubjects(updatedSubjects); // Update selected subjects right after saving
    setSortedSubjects(updatedSubjects); // Update sorted subjects after save
    setSubjectsSaved(true);
  };

  const handleTeacherChange = (subjectId, teacherId) => {
    setExamSpecificTeachers((prev) => ({
      ...prev,
      [subjectId]: teacherId,
    }));
  };

  // Prioritize exam-specific teacher override for each subject
  const getTeacherName = (subjectId) => {
    const selectedExamObj = exams.find((exam) => exam.name === selectedExam);
    const examTeacher = selectedExamObj?.teachers[subjectId]; // Get the exam-specific teacher override

    // If there's an override in the exam, use that, otherwise use the default subject teacher
    const teacherId =
      examTeacher || subjects.find((sub) => sub.id === subjectId)?.teacherId;
    const teacher = staff.find((staffMember) => staffMember.id === teacherId);
    return teacher ? teacher.name : "No teacher assigned";
  };

  // Calculate the total marks for each subject across all students
  const calculateTotalMarksForSubject = (subjectName) => {
    let total = 0;
    studentClasses.forEach((studentClass) => {
      studentClass.students_list.forEach((student) => {
        const studentGrade =
          grades[studentClass.id]?.[student.id]?.[subjectName];
        if (studentGrade) {
          total += studentGrade;
        }
      });
    });
    return total;
  };

  // Calculate the overall percentage for a student
  const calculateStudentPercentage = (studentClass, studentId) => {
    const studentGrades = grades[studentClass.id]?.[studentId] || {};
    let totalMarks = 0;
    let subjectsWithScores = 0;

    selectedSubjects.forEach((subjectId) => {
      const subjectName = subjects.find(
        (subject) => subject.id === subjectId
      )?.name;
      const subjectGrade = studentGrades[subjectName];
      if (subjectGrade && subjectGrade > 0) {
        totalMarks += subjectGrade;
        subjectsWithScores++;
      }
    });

    if (subjectsWithScores === 0) return 0; // Avoid division by 0
    return totalMarks;
  };

  // Calculate the class position for students
  const calculateStudentPositions = (studentClass) => {
    const studentScores = studentClass.students_list.map((student) => ({
      id: student.id,
      total: calculateStudentPercentage(studentClass, student.id),
    }));

    studentScores.sort((a, b) => b.total - a.total);
    return studentScores.map((student, index) => ({
      ...student,
      position: index + 1,
    }));
  };

  // Calculate the class position for subjects
  const calculateSubjectPositions = () => {
    const subjectTotals = selectedSubjects.map((subjectId) => {
      const subjectName = subjects.find(
        (subject) => subject.id === subjectId
      )?.name;
      const total = calculateTotalMarksForSubject(subjectName);
      return { id: subjectId, total };
    });

    subjectTotals.sort((a, b) => b.total - a.total);
    return subjectTotals.map((subject, index) => ({
      ...subject,
      position: index + 1,
    }));
  };

  // Sort by student position (triggered by button)
  const sortStudentClassesByPosition = () => {
    const sortedClasses = studentClasses.map((studentClass) => {
      const sortedStudents = [...studentClass.students_list].sort(
        (a, b) =>
          calculateStudentPercentage(studentClass, b.id) -
          calculateStudentPercentage(studentClass, a.id)
      );
      return { ...studentClass, students_list: sortedStudents };
    });
    setSortedStudentClasses(sortedClasses);
  };

  // Sort by subject position (triggered by button)
  const sortSubjectsByPosition = () => {
    const sorted = [...selectedSubjects].sort((a, b) => {
      const subjectNameA = subjects.find((subject) => subject.id === a)?.name;
      const subjectNameB = subjects.find((subject) => subject.id === b)?.name;
      return (
        calculateTotalMarksForSubject(subjectNameB) -
        calculateTotalMarksForSubject(subjectNameA)
      );
    });
    setSortedSubjects(sorted);
  };

  const confirmGrades = async (subjectId) => {
    const db = getFirestore();
    const selectedExamObj = exams.find((exam) => exam.name === selectedExam);

    if (selectedExamObj) {
      const examDocRef = doc(db, "exams", selectedExamObj.id);
      const updatedSubjectsConfirmed = {
        ...selectedExamObj.subjectsConfirmed,
        [subjectId]: true, // Mark the subject as confirmed by the teacher
      };

      // Update Firestore to mark subject grades as confirmed
      await updateDoc(examDocRef, {
        subjectsConfirmed: updatedSubjectsConfirmed,
      });

      // Update the state
      setExams((prevExams) =>
        prevExams.map((exam) =>
          exam.id === selectedExamObj.id
            ? { ...exam, subjectsConfirmed: updatedSubjectsConfirmed }
            : exam
        )
      );
    }
  };

  const canConfirmGrades = (subjectId) => {
    // Check if a teacher is manually assigned for this subject in the `teachers` map
    const manualTeacherEmail = examSpecificTeachers[subjectId];

    // If no manual teacher, fallback to the default teacher in the `subjects` collection
    const defaultTeacherEmail = subjects.find(
      (subject) => subject.id === subjectId
    )?.teacherId;

    // Compare the logged-in user's email with the manually assigned teacher or the default teacher
    const teacherEmail = manualTeacherEmail || defaultTeacherEmail;

    // Allow access if the logged-in user is either the teacher or an admin
    return isAdmin || teacherEmail === userData.email;
  };

  const totalSubjects = sortedSubjects.length; // Number of subjects in sortedSubjects

  const downloadPDF = () => {
    const table = document.querySelector(".student-grades-table-parent");

    html2canvas(table).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF();
      pdf.addImage(imgData, "PNG", 10, 10, 190, 0); // Adjust the dimensions as needed
      pdf.save("student_grades.pdf");
    });
  };

  const renderView = () => {
    return (
      <div>
        {" "}
        <div>
          <label htmlFor="examSelector">Select Exam:</label>{" "}
          <select
            id="examSelector"
            value={selectedExam}
            onChange={(e) => {
              const selected = exams.find(
                (exam) => exam.name === e.target.value
              );
              setSelectedExam(selected.name);
              setSelectedSubjects(selected.subjects || []);
              setSortedSubjects(selected.subjects || []);
              setExamSpecificTeachers(selected.teachers || {});
              setSubjectsSaved(selected.subjects.length > 0);
              localStorage.setItem("selectedExam", selected.name);
              fetchGradesForExam(selected.name, studentClasses);
            }}
          >
            {exams.map((exam) => (
              <option key={exam.id} value={exam.name}>
                {exam.name} ({exam.studentClassName})
              </option>
            ))}
          </select>{" "}
          <button onClick={toggleSubjectSelection}>
            {showSubjectSelection
              ? "Hide subject selection"
              : "Select subjects for this exam"}
          </button>
        </div>
        <br />
        {showSubjectSelection && (
          <div>
            <h3>Select Subjects and Assign Teachers for this Exam</h3>
            {subjects.map((subject) => (
              <div key={subject.id}>
                <label>
                  <input
                    type="checkbox"
                    checked={selectedSubjects.includes(subject.id)}
                    onChange={() => handleSubjectSelection(subject.id)}
                  />
                  {subject.name}
                </label>
                {selectedSubjects.includes(subject.id) && (
                  <select
                    value={
                      examSpecificTeachers[subject.id] || subject.teacherId
                    }
                    onChange={(e) =>
                      handleTeacherChange(subject.id, e.target.value)
                    }
                  >
                    {staff.map((staffMember) => (
                      <option key={staffMember.id} value={staffMember.id}>
                        {staffMember.name}
                      </option>
                    ))}
                  </select>
                )}
              </div>
            ))}
            <br />
            <button
              onClick={() => {
                const exam = exams.find((exam) => exam.name === selectedExam);
                handleSaveExamSubjects(exam.id);
              }}
            >
              Save Exam Subjects and Teachers
            </button>
          </div>
        )}
        <br />
        {totalSubjects > 0 &&
          sortedStudentClasses.map((studentClass) => {
            const studentPositions = calculateStudentPositions(studentClass);
            return (
              <div key={studentClass.id}>
                <h3>Class: {studentClass.name}</h3>
                <button onClick={sortStudentClassesByPosition}>
                  Sort by Class Position
                </button>
                <button onClick={sortSubjectsByPosition}>
                  Sort by Subject Position
                </button>

                {/* <button onClick={downloadPDF}>Download Table as PDF</button> */}
                <div className="student-grades-table-parent">
                  <table className="student-grades-table">
                    <thead>
                      <tr>
                        <th className="top-left-sg-table" rowSpan="2">
                          {selectedExam}
                        </th>
                        {subjectsSaved &&
                          sortedSubjects.map((subjectId) => (
                            <th key={subjectId}>
                              {
                                subjects.find((sub) => sub.id === subjectId)
                                  ?.abbreviation
                              }{" "}
                            </th>
                          ))}
                      </tr>

                      <tr>
                        {subjectsSaved &&
                          sortedSubjects.map((subjectId) => (
                            <th key={subjectId} className="small-font">
                              {getTeacherName(subjectId)}
                            </th>
                          ))}
                      </tr>
                      <br />
                      <tr>
                        <td>Total Marks</td>
                        {sortedSubjects.map((subjectId) => {
                          const subjectName = subjects.find(
                            (sub) => sub.id === subjectId
                          )?.name;
                          return (
                            <td key={subjectId}>
                              {calculateTotalMarksForSubject(subjectName)}
                            </td>
                          );
                        })}
                      </tr>
                      <tr>
                        <td>Subject Position</td>
                        {calculateSubjectPositions().map((subject) => (
                          <td key={subject.id}>{subject.position}</td>
                        ))}
                      </tr>
                      <br />
                      <tr>
                        <th>Students</th>
                        <th colSpan={totalSubjects}></th>
                        <th>Marks</th>
                        <th>Position</th>
                      </tr>
                    </thead>

                    <tbody>
                      {studentClass.students_list.map((student) => {
                        const studentPosition = studentPositions.find(
                          (pos) => pos.id === student.id
                        );
                        return (
                          <tr key={student.id}>
                            <td>{student.name}</td>
                            {sortedSubjects.map((subjectId) => {
                              const subject = subjects.find(
                                (sub) => sub.id === subjectId
                              );
                              const subjectName = subject?.name; // Use name for fetching/storing grades
                              return (
                                <td key={subjectId}>
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    {saveStatus[student.id] === "loaded" ? (
                                      <div className="grade-input-saving"></div>
                                    ) : (
                                      <input
                                        type="number"
                                        className="student-grades-input"
                                        value={
                                          grades[studentClass.id]?.[
                                            student.id
                                          ]?.[subjectName] || "" // Use name to fetch grades
                                        }
                                        onChange={(e) =>
                                          handleGradeChange(
                                            studentClass.id,
                                            student.id,
                                            subjectName, // Use name when updating grades
                                            e.target.value
                                          )
                                        }
                                        onBlur={() =>
                                          handleSaveGrades(
                                            studentClass.id,
                                            student.id
                                          )
                                        }
                                        min="0"
                                        step="1"
                                        disabled={
                                          !subjectsSaved ||
                                          !canConfirmGrades(subjectId)
                                        }
                                      />
                                    )}
                                  </div>
                                </td>
                              );
                            })}

                            <td>
                              {calculateStudentPercentage(
                                studentClass,
                                student.id
                              )}
                            </td>
                            <td>{studentPosition?.position}</td>
                          </tr>
                        );
                      })}
                    </tbody>

                    <tfoot>
                      <tr>
                        <td></td>
                        {/* Inside your JSX for the button rendering */}
                        {selectedSubjects.map((subjectId) => {
                          // Find the subject by ID to get its name
                          const subjectName = subjects.find(
                            (subject) => subject.id === subjectId
                          )?.name;

                          return (
                            <td key={subjectId}>
                              {exams.find((exam) => exam.name === selectedExam)
                                ?.subjectsConfirmed?.[subjectId] ? (
                                <span>Sent</span> // Display "Sent" as text once the grades are confirmed
                              ) : (
                                <button
                                  onClick={() => {
                                    if (canConfirmGrades(subjectId)) {
                                      if (
                                        window.confirm(
                                          `You are about to confirm your grades for ${subjectName}. Once sent, you will need to contact management to change a grade.`
                                        )
                                      ) {
                                        confirmGrades(subjectId); // Call confirmGrades function
                                      }
                                    }
                                  }}
                                  className="confirm-button"
                                  hidden={!canConfirmGrades(subjectId)} // Disable if no access
                                >
                                  Confirm
                                </button>
                              )}
                            </td>
                          );
                        })}
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </div>
            );
          })}
      </div>
    );
  };

  return (
    <>
      <div>
        <Locationscroll />
        <div className="row2" id="flex">
          <div className="main">
            <div>
              <h2>Student Grades</h2>
              {loading ? <div>Loading...</div> : renderView()}
            </div>
            <br />
          </div>
        </div>
      </div>
    </>
  );
};

export default StudentGrades;
