import { createContext, useContext, useState, useEffect } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../../firebase';

const UsersContext = createContext();

export const UsersProvider = ({ children }) => {
  const [users, setUsers] = useState([]);

  useEffect(() => {
    console.log("Fetching users..."); // Log before fetching users

    const fetchUsers = async () => {
      console.log("Inside fetchUsers"); // Log when entering fetchUsers function

      const usersCollection = collection(db, 'users');
      const usersSnapshot = await getDocs(usersCollection);
      const usersData = usersSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      console.log("Fetched users:", usersData); // Log fetched users data
      setUsers(usersData);
    };

    fetchUsers();
  }, []);

  console.log("Users:", users); // Log users state after useEffect

  return (
    <UsersContext.Provider value={{ users }}>
      {children}
    </UsersContext.Provider>
  );
};

export const useUsers = () => useContext(UsersContext);
