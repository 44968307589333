import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Navbar,
  Footer,
  Side,
  Slideshow,
  Section,
  MyLink,
} from "../../components";
import { doc, updateDoc, getDoc, deleteDoc } from "firebase/firestore";
import { db } from "../../firebase";
import { useParams } from "react-router-dom";
import NextItems from "../../components/NextItems/NextItems";
import { useWhatWeDo } from "../WhatWeDo/WhatWeDoContext";

const Wwd = ({ isAdmin }) => {
  const [isProcessing, setIsProcessing] = useState(false);
  const { whatWeDo, setWhatWeDo, whatWeDoLoading } = useWhatWeDo(); // Destructure whatWeDoLoading from the context
  const { wwdUrl } = useParams();
  const navigate = useNavigate();

  const wwd = whatWeDo.find((wwd) => wwd.url === wwdUrl);

  const toggleWwdStatus = async () => {
    if (!wwdUrl) return; // Guard clause

    const wwdRef = doc(db, "whatWeDo", wwdUrl); // Construct Firestore document reference based on the URL

    try {
      setIsProcessing(true);
      const docSnapshot = await getDoc(wwdRef); // Retrieve document snapshot

      if (docSnapshot.exists()) {
        const currentStatus = docSnapshot.data().status;
        const newStatus = currentStatus === "Published" ? "Draft" : "Published";

        await updateDoc(wwdRef, { status: newStatus });

        console.log(`Wwd status updated to ${newStatus}`);
        setTimeout(() => {
          // Update the status in the context state
          const updatedWhatWeDo = whatWeDo.map((item) =>
            item.url === wwdUrl ? { ...item, status: newStatus } : item
          );
          setWhatWeDo(updatedWhatWeDo);
          setIsProcessing(false);
        }, 2000);
      } else {
        alert("Wwd document does not exist");
      }
    } catch (error) {
      alert("Error updating Wwd status: ", error);
    }
  };

  const deleteWwdDocument = async () => {
    try {
      setIsProcessing(true);
      await deleteDoc(doc(db, "whatWeDo", wwdUrl));
      // Remove the deleted document from the state
      const updatedWhatWeDo = whatWeDo.filter((item) => item.url !== wwdUrl);
      setWhatWeDo(updatedWhatWeDo);
      setTimeout(() => {
        navigate("/admin/programs");
        setIsProcessing(false);
      }, 2000);
    } catch (error) {
      console.error("Error deleting Wwd document:", error);
    }
  };

  return (
    <div>
      <Navbar isAdmin={isAdmin} />
      <div className="row" id="flex">
        <Side isProcessing={isProcessing} />
        <div className="main">
          {whatWeDoLoading || !wwd ? (
            <div className="loading-container">
              <div className="loading-animation"></div>
              <div className="loading-text">Loading...</div>
            </div>
          ) : !wwd ? (
            <div>
              <p>No article found</p> <br />
              <MyLink to="/">
                <u>Go Home</u>
              </MyLink>
            </div>
          ) : (
            <div>
              <MyLink to={`/programs#${wwdUrl}`}>
                <div className="div-back">
                  <p className="allNewsLetters">
                    <i className="fa-solid fa-arrow-left"></i>Back to Programs
                  </p>
                </div>
              </MyLink>
              {isAdmin && (
                <>
                  {wwd && (
                    <>
                      <span className={`status-${wwd.status?.toLowerCase()}`}>
                        {wwd.status === "Published" ? (
                          <>
                            {wwd.status} <i className="fa fa-check-circle"></i>
                          </>
                        ) : (
                          <>
                            {wwd.status} <i className="fa fa-pencil-alt"></i>
                          </>
                        )}
                      </span>
                      <span
                        className="div-readmore status"
                        onClick={toggleWwdStatus}
                      >
                        {" "}
                        {wwd.status === "Published" ? (
                          <>
                            <i className="fa-solid fa-download"></i> Unpublish
                          </>
                        ) : (
                          <>
                            <i className="fa-solid fa-upload"></i> Publish
                          </>
                        )}
                      </span>
                      <span
                        className="div-readmore status"
                        onClick={deleteWwdDocument}
                      >
                        <i className="fa-solid fa-trash-can"></i> Delete
                      </span>
                    </>
                  )}
                </>
              )}
              {isAdmin && (
                <p className="red">
                  Updating program text does not work yet. Reach out to Magnus
                  if you want to change the content.
                </p>
              )}
              {wwd && (
                <div className="article" id={wwdUrl}>
                  <Section
                    wwdId={wwd.url}
                    header={wwd.title}
                    coming={wwd.coming}
                    instructor={wwd.instructor}
                    places={wwd.places}
                    openingHours={wwd.openingHours}
                    description={wwd.description}
                    img={wwd.img}
                    isAdmin={isAdmin}
                    isArticle={false}
                  />
                  {wwd?.sections?.map((section) => (
                    <div>
                      <Section
                        wwdId={wwd.url}
                        sectionId={section.section_id}
                        title={section.section_title}
                        img={section.section_image}
                        sectionDescription={section.section_description}
                        isAdmin={isAdmin}
                        isArticle={false}
                      />
                    </div>
                  ))}
                </div>
              )}
              {/* <br />
              <div className="article">
                <NextItems
                  items={whatWeDo}
                  currentIdentifier={wwdUrl}
                  identifierType="url"
                />
              </div> */}
            </div>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Wwd;
