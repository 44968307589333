import React, { useState } from "react";
import { Navbar, Footer, Section, MyLink } from "../../components";
import { SelectArticle } from "../../components";
import Locationscroll from "../../components/Locationscroll/Locationscroll";
import Loading from "../../components/Loading/Loading";
import { useArticles } from "../Articles/ArticlesContext";

const Articles = ({ isAdmin }) => {
  const { articles, articlesLoading, addArticle } = useArticles();
  const [isProcessing, setIsProcessing] = useState(false);

  const articleYears = [
    ...new Set(articles.map((article) => article.year)),
  ].sort((a, b) => b - a);

  const createNewArticle = async () => {
    if (articles.length === 0) {
      console.error("No articles found. Can't determine the next ID.");
      return;
    }

    const newArticleData = {
      // Assuming default values for a new article
      title: "New Article Title",
      description:
        "Description of the new article. Kindly ensure that the section length somehow matches the height of the section photo.",
      year: new Date().getFullYear().toString(),
      month: new Date().toLocaleString("default", { month: "long" }),
      day: new Date().getDate(),
      status: "Draft",
      img: "",
    };

    setIsProcessing(true);
    await addArticle(newArticleData);
    setIsProcessing(false);
  };

  return (
    <div>
      <Navbar isAdmin={isAdmin} />
      <div className="row" id="flex">
        <div className="main">
          <div className="row">
            <div>
              <div>
                <MyLink to="/programs">
                  <div className="div-back">
                    <p className="allNewsLetters">
                      <i className="fa-solid fa-arrow-left"></i>Back to Programs
                    </p>
                  </div>
                </MyLink>
                {isAdmin && (
                  <>
                    <span
                      className="div-readmore status createArticleBtn greenBg"
                      onClick={createNewArticle}
                    >
                      Create new article{" "}
                      {isProcessing ? (
                        <span className="rolling-loading-animation small"></span>
                      ) : (
                        <i className="fa-solid fa-plus"></i>
                      )}
                    </span>
                  </>
                )}
              </div>
              <div
                className="article newsletters"
                style={{ cursor: "default" }}
              >
                <h2>Newsletters</h2>
                <SelectArticle />
              </div>
              {articlesLoading ? (
                <></>
              ) : (
                <>
                  <Locationscroll />
                  <div className="timeline">
                    {articleYears.map((year) => (
                      <div key={year} className="timeline__group">
                        <span
                          className="timeline__year time"
                          aria-hidden="true"
                        >
                          {year}
                        </span>
                        <div className="timeline__cards">
                          {articles
                            .filter(
                              (article) =>
                                article.year === year &&
                                (isAdmin || article.status === "Published")
                            )
                            .map((article) => (
                              <div
                                key={article.id}
                                className="timeline__card article-card"
                                id={article.id}
                              >
                                {isAdmin && (
                                  <div
                                    className={`article-status article-status-${article.status.toLowerCase()}`}
                                  >
                                    <span
                                      className={`status-${article.status.toLowerCase()}`}
                                    >
                                      {article.status === "Published" ? (
                                        <>{article.status} </>
                                      ) : (
                                        <>
                                          {article.status}{" "}
                                          <i className="fa fa-pencil-alt"></i>
                                        </>
                                      )}
                                    </span>{" "}
                                  </div>
                                )}
                                <Section
                                  articleId={article.id}
                                  header={article.title}
                                  day={article.day}
                                  month={article.month}
                                  year={article.year}
                                  description={article.description}
                                  img={article.img}
                                  readMore="Read more"
                                  isAdmin={false}
                                />
                              </div>
                            ))}
                        </div>
                      </div>
                    ))}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Articles;
